<template>
    <div v-show="open" v-cloak class="fixed z-50 overflow-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true" :class="{'inset-0': !inWidget, 'left-0 right-0 top-1/3 sm:inset-0': inWidget}">
        <div class="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0" :class="{'sm:min-h-screen': !inWidget}">
            <!-- Background overlay, show/hide based on modal state. -->
            <transition
                name="background-overlay"
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-show="open" @click="close" class="fixed inset-0 -z-10 bg-gray-500/75 transition-opacity" aria-hidden="true" />
            </transition>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

            <!-- Modal panel, show/hide based on modal state. -->
            <transition
                name="modal-panel"
                enter-active-class="ease-out duration-300"
                enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enter-to-class="opacity-100 translate-y-0 sm:scale-100"
                leave-active-class="ease-in duration-200"
                leave-class="opacity-100 translate-y-0 sm:scale-100"
                leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div v-if="open" class="relative inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle w-full sm:p-6" :class="[size, bgClass, contentClass, overflow]">
                    <div v-if="withDismiss" class="sm:block absolute top-0 right-0 pt-3 pr-3">
                        <button @click="close()" type="button" class="text-gray-400 hover:text-gray-500 focus:outline-hidden focus:ring-2 p-1 lg:p-2 rounded-full hover:bg-gray-50 focus:ring-blue-500">
                            <span class="sr-only">Close</span>
                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <slot />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        size: { default:'sm:max-w-lg', type:String },
        bgClass: { default:'bg-white', type:String },
        contentClass: { default:'', type:String },
        allowOverflow: { default: false, type: Boolean },
        withDismiss: { default:false, type:Boolean },
        inWidget: { default:false, type:Boolean },
        isOpen: { default:false, type:Boolean },
    },
    data: () => ({
        open: false,
    }),

    computed: {
        overflow() {
            return this.allowOverflow ? 'overflow-visible' : 'overflow-hidden';
        }
    },

    watch: {
        isOpen(value){
            if (! value) {
                this.close();

                return;
            }

            this.show();
        },
    },
    methods: {
        escape(event){
            if (event.key === 'Escape') {
                this.close();
            }
        },
        show(){
            this.open = true;
        },
        close(){
            this.open = false;
            this.$emit('modal-closed');
        }
    },
    created() {
        window.addEventListener('keydown', this.escape);
    },
    destroyed(){
        window.removeEventListener('keydown', this.escape);
    },
};
</script>
