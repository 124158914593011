<template>
    <p v-if="error" class="mt-2 text-sm text-red-600">
        <span v-if="message">{{ message }}</span>
        <slot />
    </p>
</template>

<script>
export default {
    props: {
        error: {default:false, type:[Boolean, Number]},
        message: {type:String},
    }
};
</script>
